<template lang="pug">
  Auth(@loggedIn="onLoggedIn"
    :redirectWithLoginFailed="true")
    Header(:content="headerContent")
    NowLoading(:show="showNowLoading")
    div.wrap-input-contact
      ModuleInvitationInputContact(ref="moduleInvitationInputContact"
        v-if="meeting" :meeting="meeting")
</template>

<style lang="scss" scoped>
.wrap-input-contact {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import database from '@/database'
import { updateMeetingAttendee } from '@/services/functions'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
import Auth from '@/components/shared/Auth'
import NowLoading from '@/components/shared/NowLoading'
import Header from '@/components/shared/Header.vue'
import ModuleInvitationInputContact from '@/components/module/ModuleInvitationInputContact'

export default {
  components: {
    Auth,
    NowLoading,
    Header,
    ModuleInvitationInputContact
  },
  data() {
    return {
      meeting: null,
      headerContent: {
        title: '連絡先を入力',
        left: {
          icon: 'mdi-chevron-left',
          action: this.onLeft
        },
        right: {
          label: '次へ',
          action: this.onRight,
          color: '#999'
        }
      },
      showNowLoading: false
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created() {
    this.meeting = await database.meetingCollection().findById(this.$route.params.meetingId)
  },
  methods: {
    onLoggedIn() {},
    onLeft() {
      const path = this.$route.params.attendeeIndex
        ? `/invitation/recommend-google-auth/${this.$route.params.meetingId}/${this.$route.params.attendeeIndex}`
        : `/invitation/recommend-google-auth/${this.$route.params.meetingId}`
      this.$router.push(path)
    },
    async onRight() {
      const moduleInvitationInputContact = this.$refs.moduleInvitationInputContact
      if (!moduleInvitationInputContact.isValid) return

      this.showNowLoading = true
      const attendeeName = this.$route.params.attendeeIndex
        ? moduleInvitationInputContact.attendee.name
        : moduleInvitationInputContact.name
      const params = {
        meetingId: this.$route.params.meetingId,
        attendee: {
          name: attendeeName,
          email: moduleInvitationInputContact.email
        }
      }
      await updateMeetingAttendee(params)

      this.$router.push(
        `/invitation/send-attendee-events/${this.$route.params.meetingId}/manual/${moduleInvitationInputContact.email}`
      )
      this.showNowLoading = false
    }
  }
}
</script>
