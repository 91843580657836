<template lang="pug">
  div.wrap-module-invitation-input
    div.invitation-input-content.py12
      v-text-field(label="名前" v-model="name"
        placeholder="山田太郎" persistent-placeholder)
      v-text-field(label="メールアドレス" v-model="email"
        :color="isValid ? '#2a2a2a' : '#ff5252'"
        placeholder="contact@example.com" persistent-placeholder)
      //- v-text-field(label="電話番号" v-model="telNo"
        placeholder="08012345678" persistent-placeholder)
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-module-invitation-input {
  width: 100%;
  min-height: 100vh;
  padding-top: $header_height;
  .invitation-input-content {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
  }
}
</style>

<script>
export default {
  props: {
    meeting: {
      type: Object,
      required: true
    }
  },
  computed: {
    attendee() {
      return this.$route.params.attendeeIndex
        ? this.meeting.attendee[Number(this.$route.params.attendeeIndex)]
        : null
    }
  },
  data() {
    return {
      name: '',
      email: '',
      telNo: '',
      isValid: false
    }
  },
  watch: {
    name() {
      this.validate()
    },
    email() {
      this.validate()
    }
  },
  created() {
    if (this.attendee) {
      this.name = this.attendee.name
      if (this.attendee.email) this.email = this.attendee.email
    }
  },
  methods: {
    validate() {
      const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
      this.isValid = reg.test(this.email) && this.name !== ''
      this.$parent.$parent.headerContent.right.color = this.isValid ? '#4285f4' : '#999'
    }
  }
}
</script>
