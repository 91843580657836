<template lang="pug">
  transition
    div(v-if="show").wrap-now-loading.f.fh
      v-progress-circular(indeterminate)
</template>

<style lang="scss" scoped>
.wrap-now-loading {
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}
.v-leave-active, .v-enter-active {
  transition: opacity 0.4s;
}
.v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave {
  opacity: 1;
}
</style>

<script>

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    }
  }
}
</script>
